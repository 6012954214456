<template>
  <div class="Map">
    <div class="top">
      <img src="@/assets/map.png" class="map-icon" alt="" />
      <div class="title">学习地图</div>
    </div>
    <div class="bottom">
      <div class="flex-alc column tip" v-if="mapType == -1">
        <img src="@/assets/nodata_1@2x.png" class="noMasterImg" alt="" />
        <div class="tipText">您还没有学习地图</div>
      </div>
      <!-- 新员工，新干部地图 -->
      <div class="new-map-container" v-if="mapType == 2 || mapType == 1">
        <img src="@/assets/pic_bg@2x.png" class="map-img" alt="" />
        <img src="@/assets/pic_all@2x.png" class="map-img1" alt="" />
        <div
          class="map-img-card"
          :class="{
            click:
              mapInfo[`task${index + 1}`] == -1 || (index == 10 && allComplete),
          }"
          @click="
            mapInfo[`task${index + 1}`] == -1 || (index == 10 && allComplete)
              ? fillTask(item)
              : ''
          "
          :style="{ left: leftList[index], top: topList[index] }"
          v-for="(item, index) in map_img_list"
          :key="'map' + index"
        >
          <img
            :src="
              newImg[
                index == 10 && allComplete
                  ? 1
                  : mapInfo[`task${index + 1}`] == 0
                  ? 2
                  : mapInfo[`task${index + 1}`] == -1
                  ? 0
                  : mapInfo[`task${index + 1}`] > 0
                  ? 1
                  : 2
              ]
            "
            class="wid_100"
            alt=""
          />
          <div class="text HYNuoMiTuanW">{{ item.title }}</div>
        </div>
      </div>
      <!-- 通用地图 -->
      <div class="old-map-container" v-if="mapType == 0">
        <img src="@/assets/oldnew.png" class="map-back-img" alt="" />
        <div class="build-container">
          <div
            v-show="index < 10"
            class="floor"
            :style="{
              'grid-row-start': map_img_list.length - index,
            }"
            v-for="(item, index) in mapInfo.stage_list"
            :key="'old' + index"
          >
            <img
              :src="
                item.status == 2
                  ? oldImgDark[index % 3]
                  : oldImgBright[index % 3]
              "
              class="old-floor-back"
              alt=""
            />
            <div
              class="card"
              @click="item.status == 1 ? lookMap(item) : ''"
              :class="{ click: item.status == 1 }"
            >
              <img :src="lockImg[item.status]" class="lock-img" alt="" />
              <div class="text one-line">
                {{ item.name }}
              </div>
            </div>
          </div>
          <img src="@/assets/ld.png" class="roof-top" />
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="isDialogStudy"
      :show-close="true"
      class="dialog"
      width="482px"
    >
      <div class="title">{{ taskObj.title }}</div>
      <el-divider></el-divider>
      <div class="status">进行中</div>
      <div
        class="cur"
        :class="{ click: item.status == 0 }"
        @click="item.status == 0 ? goCur(item) : ''"
        v-for="(item, index) in taskDetai"
        :key="'Cur' + index"
      >
        <div class="flex-alc">
          <div class="type">{{ item.kind ? "测评" : "课程" }}</div>
          <div class="name">{{ item.fmt_title }}</div>
        </div>
        <div class="goTask" :class="{ complete: item.status == 1 }">
          {{ item.status == 1 ? "已完成" : "点击去完成 >>" }}
        </div>
      </div>
      <div class="ps">ps:{{ taskObj.tip }}</div>
      <div
        class="button click"
        :class="{ 'back-gray': !isComplete }"
        @click="isComplete ? submitTask() : ''"
      >
        确定
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="isDialog"
      :show-close="true"
      class="dialog"
      width="482px"
    >
      <div class="title">{{ currencyMap.name }}</div>
      <el-divider></el-divider>
      <div class="status">进行中</div>
      <div
        class="cur"
        :class="{ click: item.status == 0 }"
        @click="item.status == 0 ? goCur(item) : ''"
        v-for="(item, index) in mapDetail.content_list"
        :key="'Cur' + index"
      >
        <div class="flex-alc">
          <div class="type">{{ item.kind ? "测评" : "MP4" }}</div>
          <div class="name">{{ item.fmt_title }}</div>
        </div>
        <div class="goTask" :class="{ complete: item.status == 1 }">
          {{ item.status == 1 ? "已完成" : "点击去完成 >>" }}
        </div>
      </div>
      <div class="button click" @click="isDialog = false">确定</div>
    </el-dialog>

    <el-dialog
      :visible.sync="showTask"
      :show-close="true"
      class="task-dialog"
      width="482px"
    >
      <div class="title">{{ taskObj.title }}</div>
      <el-divider></el-divider>
      <div
        class="pr-35 pl-35 mb-15 wid_100"
        v-show="taskObj.type == 0 || taskObj.type == 2"
      >
        <el-input
          type="textarea"
          :placeholder="taskObj.placeholder || '说点什么吧'"
          v-model="textarea"
          :maxlength="800"
          show-word-limit
          :rows="5"
        >
        </el-input>
      </div>
      <div class="upload" v-show="taskObj.type == 1 || taskObj.type == 2">
        <!-- <div class="task-title">上传附件</div> -->
        <el-upload
          :action="ossToken.host"
          list-type="picture"
          :on-change="handleChange"
          :data="uploadData"
          :before-upload="beforUpload"
          class="pb-15"
          ref="upload"
          :file-list="file"
          :limit="taskObj.limit"
        >
          <el-button
            size="small"
            v-show="file.length < taskObj.limit"
            class="mb-10"
            type="primary"
            >点击上传附件</el-button
          >
        </el-upload>
        <div class="ps">ps:{{ taskObj.tip }}</div>
      </div>
      <div class="button click" @click="submitTask">确定</div>
    </el-dialog>

    <!-- <LearnTeacherSuccess
            @close="close"
            :type="false"
            text="恭喜您！ 正式成为彰泰集团的正式员工， 持续为公司未来的发展添砖加瓦！"
            v-if="showSuccess"
        ></LearnTeacherSuccess> -->
    <LearnTeacherSuccess
      @close="close"
      :type="false"
      text="恭喜您！"
      v-if="showSuccess"
    ></LearnTeacherSuccess>
  </div>
</template>

<script>
import LearnTeacherSuccess from "@/components/LearnTeacherSuccess.vue";
export default {
  name: "Map",
  components: {
    LearnTeacherSuccess,
  },
  data() {
    return {
      leftList: [
        "157px",
        "327px",
        "292px",
        "103px",
        "289px",
        "360px",
        "148px",
        "159px",
        "325px",
        "120px",
        "205px",
      ], //左边偏移
      topList: [
        "963px",
        "866px",
        "693px",
        "669px",
        "547px",
        "439px",
        "496px",
        "350px",
        "190px",
        "170px",
        "20px",
      ], //顶部偏移
      newImg: [
        require("@/assets/pic_green@2x.png"),
        require("@/assets/pic_pink@2x.png"),
        require("@/assets/pic_yellow@2x.png"),
      ], //新员工地图  气泡类型
      oldImgDark: [
        require("@/assets/af.png"),
        require("@/assets/ah.png"),
        require("@/assets/al.png"),
      ], //正式员工地图  盖楼类型 暗图
      oldImgBright: [
        require("@/assets/lf.png"),
        require("@/assets/lh.png"),
        require("@/assets/ll.png"),
      ], //正式员工地图  盖楼类型 亮图
      lockImg: [
        require("@/assets/ic_wks@2x.png"),
        require("@/assets/ic_jxz@2x.png"),
        require("@/assets/ic_ywc@2x.png"),
      ], //锁.灯.解锁图
      newStaffList: [
        {
          title: "展示自我",
          type: 0,
          id: 1,
          placeholder: "请编辑发布个人自我介绍信息：特长、爱好等！",
          tip: "",
        },
        {
          title: "【拜师】结友",
          type: 0,
          id: 2,
          placeholder: "请编辑拜师心得！",
          tip: "",
        },
        {
          title: "熟悉办公环境",
          type: 1,
          id: 3,
          placeholder: "",
          tip: "请上传您了解到的办公环境及周边情况的照片！",
          limit: 10,
        },
        {
          title: "拜师【结友】",
          type: 0,
          id: 4,
          placeholder: "请输入3名伙伴的姓名、岗位、爱好、兴趣！",
          tip: "",
        },
        {
          title: "试用目标",
          type: 1,
          id: 5,
          placeholder: "",
          tip: "请制定不少于3个的试用期工作目标！",
          limit: 5,
        },
        {
          title: "阶段学习心得",
          type: 0,
          id: 6,
          placeholder: "请编辑不少于150字的阶段学习心得！",
          tip: "",
        },
        {
          title: "必学知识",
          type: 3,
          id: 7,
          placeholder: "",
          tip: "请完成集团系列课程学习！",
        },
        {
          title: "新员工培训",
          type: 3,
          id: 8,
          placeholder: "",
          tip: "请参加新员工培训考试！",
        },
        {
          title: "温馨对话",
          type: 0,
          id: 9,
          placeholder: "请编辑不少于150字的沟通成果及心得！",
          tip: "",
        },
        {
          title: "转正大考",
          type: 2,
          id: 10,
          tip: "请上传转正答辩PPT材料（M1/P4级及以上）",
          placeholder: "编写转正业绩说明！",
          limit: 5,
        },
        { title: "转正贺信", type: 4, id: 11 },
      ], //新员工 type 0文本 1附件 2文本和附件 3课程 4贺信弹窗
      oldStaffList: [
        {
          title: "展示自我",
          type: 0,
          id: 1,
          tip: "",
          placeholder: "请编辑发布个人自我介绍信息：特长、爱好等！",
        },
        {
          title: "【拜师】结友",
          type: 0,
          id: 2,
          tip: "",
          placeholder: "请编辑拜师心得！",
        },
        {
          title: "熟悉办公环境",
          type: 1,
          id: 3,
          tip: "请上传您了解到的办公环境及周边情况的照片！",
          placeholder: "",
          limit: 10,
        },
        {
          title: "拜师【结友】",
          type: 0,
          id: 4,
          tip: "",
          placeholder: "请输入3名伙伴的姓名、岗位、爱好、兴趣！",
        },
        {
          title: "531计划",
          type: 1,
          id: 5,
          tip: "请上传由单位负责人签字确认的531计划！",
          placeholder: "",
          limit: 10,
        },
        {
          title: "集团/标杆区域轮岗",
          type: 0,
          id: 6,
          tip: "",
          placeholder: "请编辑不少于150字的轮岗学习心得！",
        },
        {
          title: "必学知识",
          type: 3,
          id: 7,
          tip: "请完成集团系列课程学习！",
          placeholder: "",
        },
        {
          title: "新干部培训",
          type: 3,
          id: 8,
          tip: "请参加干部培训考试！",
          placeholder: "",
        },
        {
          title: "温馨对话",
          type: 0,
          id: 9,
          tip: "",
          placeholder: "请编辑不少于150字的沟通成果及心得！",
        },
        {
          title: "转正大考",
          type: 1,
          id: 10,
          tip: "请上传转正答辩PPT材料！",
          limit: 10,
        },
        { title: "转正贺信", type: 4, id: 11 },
      ], //新干部 type 0文本 1附件 2文本和附件 3课程 4贺信弹窗
      map_img_list: [], //地图列表
      isDialog: false, //显示地图详情
      showTask: false, //显示任务填写弹窗
      taskObj: {}, //新员工、干部任务对象
      taskDetai: "", //新员工、干部任务详情(第七，第八任务)
      showSuccess: false, //贺信显示

      file: [], //文件
      textarea: "", //文本

      mapType: -2, //地图类型 -1无地图 0 通用 1新员工 2新干部
      mapInfo: {}, //地图详情
      allComplete: false, //任务是否全部完成
      currencyMap: {},
      mapDetail: {}, //通用地图详情

      fileName: "", //上传文件的名字
      isDialogStudy: false, //必学知识弹框

      isComplete: false, //是否能点击完成
    };
  },

  computed: {
    ossToken: function() {
      return this.$store.state.ossToken;
    },
    uploadData: function() {
      return {
        key: "",
        policy: this.ossToken.policy,
        OSSAccessKeyId: this.ossToken.accessid,
        signature: this.ossToken.signature,
      };
    },
  },

  watch: {
    $route() {
      this.changNav();
    },
  },

  mounted() {
    this.getMap();
    this.changNav();
  },

  methods: {
    changNav() {
      let source = this.$route.query.source || 0;
      if (source == 1) {
        this.$router.replace({ path: "/myInfo" });
      } else {
        window.localStorage.setItem("myNavActive", 5);
        this.$store.commit("switchMyNav", 5);
      }
    },
    //新员工 新干部获取任务课程、测评
    async getTask() {
      let res = await this.$https.get("/api/user/learn_stage", {
        id: this.taskObj.id,
        kind: this.mapType,
      });
      if (res.errcode) return this.$message(res.msg);
      this.taskDetai = res.data;
      let isSuccess = true;
      this.taskDetai.forEach((i) => {
        if (!i.status) {
          isSuccess = false;
        }
      });
      if (isSuccess) {
        this.isComplete = true;
      }
    },
    //查看课程
    goCur(e) {
      if (e.kind == 0) {
        return this.$router.push({
          path: "/CurriculumDetail",
          query: { id: e.fmt_id },
        });
      }
      if (e.kind == 1) {
        return this.$router.push({
          path: "/InTest",
          query: { test: e.fmt_id },
        });
      }
      this.$router.push({
        path: "/CurriculumDetail",
        query: { id: e.id },
      });
    },
    //查看通用地图详情
    lookMap(e) {
      this.$https
        .get("/api/user/learn_stage", { id: e.id })
        .then((res) => {
          if (res.errcode) return this.$message.error(res.msg);
          this.mapDetail = res.data;
          this.isDialog = true;
          this.currencyMap = e;
        });
    },
    //提交任务
    submitTask() {
      if (this.taskObj.type == 0 || this.taskObj.type == 2) {
        if (!this.textarea) return this.$message.error("请填写内容");
      }
      if (this.taskObj.type == 1 || this.taskObj.type == 2) {
        if (!this.file.length) return this.$message.error("请上传文件");
      }
      let url = "";
      url = this.file
        .map((obj) => {
          return `${this.ossToken.host}/${obj.name}`;
        })
        .join(",");
      this.$https
        .post("/api/user/map_tasks", {
          status: this.mapType == 1 ? 0 : this.mapType == 2 ? 1 : "",
          target: this.taskObj.id,
          title: this.taskObj.title,
          content: this.textarea || "",
          img: url || "",
        })
        .then((res) => {
          if (res.errcode) return this.$message.error(res.msg);
          this.getMap();
          this.$refs.upload?.clearFiles();
          this.file = [];
          this.textarea = "";
          this.showTask = false;
          this.isDialogStudy = false;
        });
    },
    //上传文件前处理
    beforUpload(file) {
      this.uploadData.key =
        (this.ossToken.dir ? this.ossToken.dir + "/" : "") + file.name;
      this.fileName = file.name;
    },
    //上传成功
    handleChange(file, fileList) {
      this.file = fileList;
    },
    //关闭贺信
    close() {
      this.showSuccess = false;
    },
    //显示任务填写框
    fillTask(e) {
      console.log(e);
      if (e.id == 11) {
        return (this.showSuccess = true);
      }
      this.taskObj = e;
      if (e.id == 7 || e.id == 8) {
        this.getTask();
        this.isDialogStudy = true;
        return;
      }
      if (e.id == 2 && !this.mapInfo.allow)
        return this.$message.error("请前往个人中心拜师！");
      this.showTask = true;
    },
    //获取地图列表
    getMap() {
      this.$https.get("/api/user/map").then((res) => {
        if (res.errcode) return (this.mapType = -1);
        this.mapType = res.data.kind;
        this.mapInfo = res.data.map_info;
        if (res.data.kind == 1) {
          this.map_img_list = this.newStaffList;
          this.mapDetail = res.data.curriculm_list;
        }
        if (res.data.kind == 2) {
          this.map_img_list = this.oldStaffList;
          this.mapDetail = res.data.curriculm_list;
        }
        //判断是否全部完成
        let count = [],
          allComplete = true;
        for (let i = 1;i < 11;i++) {
          count.push("task" + i);
        }
        for (let value in res.data.map_info) {
          if (count.indexOf(value) != -1) {
            if (!res.data.map_info[value] > 0) {
              allComplete = false;
            }
          }
        }
        this.allComplete = allComplete;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Map {
  width: 100%;
  .top {
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .map-icon {
      width: 37px;
      height: 34px;
      margin-right: 12px;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #2d2d2d;
    }
  }
  .bottom {
    padding: 25px 0;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    .tip {
      .noMasterImg {
        width: 134px;
        height: 112px;
        margin-top: 66px;
      }
      .tipText {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-top: 31px;
        margin-bottom: 21px;
      }
    }
    .new-map-container {
      width: 576px;
      position: relative;
      .map-img {
        width: 100%;
      }
      .map-img1 {
        position: absolute;
        width: 536px;
        top: 18px;
        left: 20px;
      }
      .map-img-card {
        position: absolute;
        width: 136px;
        height: 38px;
        .text {
          font-size: 14px;
          color: #ff6464;
          position: absolute;
          top: 0px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
        .HYNuoMiTuanW {
          font-family: HYNuoMiTuanW;
        }
      }
    }
    .old-map-container {
      width: 780px;
      position: relative;
      display: flex;
      .map-back-img {
        width: 100%;
      }
      .build-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-bottom: 50px;
        .floor {
          width: 291px;
          height: 126px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .old-floor-back {
            width: 100%;
            height: 100%;
          }
          .card {
            position: absolute;
            width: 235px;
            height: 53px;
            padding: 15px 20px;
            opacity: 0.8;
            background: #ffffff;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .lock-img {
              width: 19px;
              height: 24px;
              margin-right: 5px;
            }
            .text {
              font-size: 16px;
              font-weight: 500;
              color: #57413b;
              width: 100%;
              text-align: center;
            }
          }
        }
        .roof-top {
          width: 337px;
          height: 68px;
        }
      }
    }
  }
  .dialog {
    /deep/.el-dialog {
      border-radius: 10px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          padding: 21px 0;
          text-align: center;
        }
        .ps {
          color: #aaa;
          font-size: 10px;
          width: 423px;
        }
        .el-divider--horizontal {
          margin-top: 0;
          margin-bottom: 17px;
        }
        .status {
          width: 77px;
          height: 25px;
          background: #fcdddd;
          border-radius: 13px;
          font-size: 16px;
          font-weight: 400;
          color: #f32929;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 23px;
        }
        .cur {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 423px;
          background: #f6f6f6;
          border-radius: 5px;
          padding: 11px 14px;
          margin-bottom: 12px;
          .type {
            border: 1px solid #e41414;
            border-radius: 11px;
            font-size: 16px;
            font-weight: 300;
            color: #e41414;
            padding: 0px 14px;
            margin-right: 7px;
          }
          .name {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            max-width: 210px;
          }
          .goTask {
            font-size: 14px;
            font-weight: 400;
            color: #e41414;
            line-height: 20px;
          }
          .complete {
            color: #a0cc61;
          }
        }
        .button {
          width: 161px;
          height: 36px;
          background: linear-gradient(180deg, #ff3764, #ff2604);
          border-radius: 18px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin-top: 48px;
          margin-bottom: 50px;
          justify-content: center;
        }
        .back-gray {
          background: #aaa;
        }
      }
    }
  }
  .task-dialog {
    /deep/.el-dialog {
      border-radius: 10px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          padding-top: 21px;
          text-align: center;
        }
        .el-textarea__inner {
          background: #f6f6f6;
          border: 1px solid #d5d5d5;
        }
        .upload {
          width: 100%;
          padding: 0 35px;
          .task-title {
            font-size: 14px;
            font-weight: 500;
            color: #2d2d2d;
            margin-bottom: 11px;
            margin-top: 25px;
          }
          .ps {
            color: #aaa;
            font-size: 10px;
          }
          .el-upload-list--picture .el-upload-list__item {
            padding: 10px;
            margin-top: 10px;
          }
          .el-upload-list--picture .el-upload-list__item-thumbnail {
            display: none;
          }
          .el-button--small,
          .el-button--small.is-round {
            // padding: 0;
            // width: 102px;
            // height: 102px;
            // background: #f6f6f6;
            // border: 1px solid #d5d5d5;
            font-size: 12px;
            font-weight: 400;
            // color: #b2b2b2;
          }
        }
        .button {
          width: 161px;
          height: 36px;
          background: linear-gradient(180deg, #ff3764, #ff2604);
          border-radius: 18px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin-top: 48px;
          margin-bottom: 50px;
          justify-content: center;
        }
      }
    }
  }
}
</style>